// http://ionicframework.com/docs/v2/theming/

// Global CSS
// --------------------------------------------------
// Put CSS rules here that you want to apply globally.
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
//
// App Shared Sass variables belong in app.variables.scss.
@import 'const-variables';
@import 'mixins/safe-area.mixin';
@import 'mixins/media.mixin';
@import 'mixins/keyframes.mixin';

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

html:not(.hydrated) body {
  display: initial !important;
}

body {
  min-width: 320px;
  user-select: none;
  -webkit-user-select: none;
  font-size: 16px;
  text-rendering: optimizeLegibility;
}

.open-barcodescanner {
  ion-router-outlet {
    visibility: hidden;
  }
  invoice-cancellation,
  product-details-modal,
  invoice-cancel-send-modal,
  ion-modal ion-backdrop {
    display: none;
  }
}

app-paymash.ios {
  font-size: 1.4rem;
}

.ios {
  app-paymash {
    font-size: 1.4rem;
  }
}

a:hover {
  opacity: 1 !important;
}

p {
  margin: 0;
}

.back-button {
  margin: 0;

  .back-button-text {
    display: none;
  }

  ion-icon {
    font-size: 22px;
    color: var(--pos-light-blue);
    font-family: sans-serif;

    &:before {
      content: '\f100';
      display: inline-block;
      font-family: 'Flaticon';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }
  }
}

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

input[type='date']::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.bar-button-menutoggle ion-icon {
  font-size: 38px;
  color: var(--pos-light-blue);
}

.table {
  display: table;
  width: 100%;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.flex-block {
  display: flex;
}

.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.btn-custom {
  display: block;
  width: auto;
  height: 44px;
  padding: 0 10px;
  margin: 0;
  border-radius: 3px;
  text-transform: none;
  font-size: 14px;
  border: 1px solid var(--pos-light-blue);
  box-shadow: none !important;
  transition: all ease-in 0.1s;

  ion-button-effect {
    display: none;
  }

  &.btn-blue {
    background: var(--pos-light-blue) !important;

    &.activated {
      background: none !important;
      color: var(--pos-light-blue);
    }
  }

  &.btn-bordered {
    color: var(--pos-light-blue);
    background: none !important;

    &.activated {
      background: var(--pos-light-blue) !important;
      color: var(--pos-primary);
    }
  }
}

.form-control {
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 0 10px;
  font-size: 16px;
  color: #000;
  box-shadow: none;
  border-radius: 0px;
  border: 1px solid var(--pos-light-blue);
  border-bottom: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-input-placeholder {
  font-weight: normal;
  color: #fff;
}

:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #fff;
}

.form-group {
  &:first-child {
    .form-control {
      border-radius: 3px 3px 0 0;
    }
  }

  &:last-child {
    .form-control {
      border-top: 1px solid var(--pos-light-blue);
      border-bottom: 1px solid var(--pos-light-blue);
      border-radius: 0 0 3px 3px;
    }
  }
}

.balance-reciept {
  .alert-wrapper {
    width: 360px !important;
    min-width: 360px !important;
    max-width: 360px !important;

    .alert-message {
      font-size: 16px !important;
      max-height: 75vh !important;
    }
  }
}

.t-all {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.t-all-f {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.t-all-sf {
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.t-max-height {
  max-height: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

button.paymash-btn {
  background: var(--pos-light-blue);
  border: 1px solid var(--pos-light-blue);
  width: 100%;
  height: 52px;
  font-size: 20px;
  font-family: HelveticaNeue;
  color: #fff;
  border-radius: 3px;

  &.bordered {
    background: transparent;
    color: var(--pos-light-blue);
  }
}
button.icon-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 6px 1px;
  .icon-button-text {
    color: var(--pos-light-blue);
    font-size: 14px;
  }
}

ion-scroll.scroll-y .scroll-content {
  overflow-y: auto !important;
}

pos-image-loader-cap {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

ion-menu {
  --width: 205px;
  --max-width: 205px;

  ion-backdrop {
    opacity: 0 !important;
  }

  .toolbar-background {
    background: transparent;
    border: none !important;
  }

  .menu-inner {
    width: 205px;
  }

  .toolbar-content {
    text-align: center;

    img {
      vertical-align: middle;
      display: inline-block;
    }
  }

  ion-item {
    --min-height: 64px;
    --padding-start: 0;
    --inner-padding-start: 20px;
  }

  .ion-toolbar-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ion-content {
    // FIX AFTER RC-1 RELEASED
    margin-top: 56px;
    background: var(--pos-grey-bg);

    current-user {
      height: 64px;
      display: block;
      border-bottom: 1px solid var(--pos-dark-grey-border) !important;

      a {
        padding: 16px 0 15px 7.5px;
      }

      person-placeholder,
      pos-image-loader-cap {
        float: left;
        margin: 0 10px;

        .person-path {
          fill: #8c8c9d !important;
        }
      }

      .info {
        max-width: 140px;
        p {
          text-align: left;
        }
        .store-name {
          color: var(--pos-black);
        }

        .employee-name {
          color: #000;
          text-align: left;
          margin-top: 7px;
        }
        &.active-store {
          .employee-name {
            margin-top: 0px;
          }
        }
      }
      .custom-icon-logout {
        margin-top: 6px;
        float: right;
        margin-right: 10px;
      }
    }
    .item {
      border: none !important;
      padding: 0;
      background: none;

      .menu-icon {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        background-image: url('/assets/images/menu-icons.png');
        background-repeat: no-repeat;
        background-size: 29px auto;

        &.menu-icon-products {
          background-position: 0 -136px;
        }

        &.menu-icon-invoices {
          background-position: 0 -102px;
        }

        &.menu-icon-report {
          background-position: 0 -68px;
        }

        &.menu-icon-admin {
          background-position: 0 -34px;
        }

        &.menu-icon-help {
          background-position: 0 -170px;
        }

        &.menu-icon-shifts {
          background-position: 0 -204px;
        }

        &.menu-icon-refer-friend {
          background-position: 0 -237px;
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .item-inner {
        padding-left: 20px;
        border-bottom: 1px solid var(--pos-dark-grey-border) !important;
      }

      ion-label {
        margin: 17px 8px 16px 0;
      }
    }
  }
}

.plt-ios {
  ion-menu {
    ion-toolbar {
      @include padding-top-safe-area();
      --background: var(--pos-white);
    }
  }
}

.platform-ios {
  ion-menu ion-content {
    margin-top: 64px;
  }
}

.alert-button {
  color: var(--pos-light-blue) !important;

  &:last-child {
    color: var(--pos-red) !important;
  }
}

ion-header::after,
[tabsPlacement='top'] > ion-tabbar::after,
ion-footer::before,
[tabsPlacement='bottom'] > ion-tabbar::before {
  display: none;
}

ion-header {
  &.header-md {
    box-shadow: none;
  }
  button {
    background: none;
  }

  .btn-current-user,
  .btn-inventory-search,
  button[menutoggle],
  .btn-barcode-scanner-open,
  .btn-connect-scanner {
    display: inline-block;
    vertical-align: middle;
  }

  .btn-inventory-search,
  .btn-barcode-scanner-open,
  .btn-connect-scanner {
    margin: 0;
  }

  .btn-barcode-scanner-open {
    padding-top: 3px;
  }

  ion-icon[name='menu-outline'] {
    font-size: 30px;
    color: var(--pos-light-blue);
    --ionicon-stroke-width: 16px;
  }

  ion-icon[name='search-outline'],
  .btn-barcode-scanner-open {
    font-size: 24px;
    color: #007aff;
    vertical-align: bottom;
  }

  ion-icon[name='search-outline'] {
    margin-top: -4px;
  }

  ion-toolbar {
    --padding-top: 2px;
    --padding-bottom: 2px;
  }
}

ion-header {
  ion-navbar.toolbar {
    padding: 0;
    min-height: 46px;

    .toolbar-content {
      display: flex;
      justify-content: space-between;
      min-height: 54px;

      &.toolbar-content-ios {
        min-height: 54px;
      }

      .left-navbar {
        width: calc(100% - #{$cart-width});
        border-right: 1px solid var(--pos-dark-grey-border);
        padding: 0;
        position: relative;

        > div {
          position: absolute;
          top: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;

          &:first-child {
            left: 0;
          }

          &:last-child {
            right: 0;
            padding-right: 15px;

            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: var(--pos-light-blue);

              span {
                padding-left: 10px;
              }
            }
          }

          &[start] {
            min-width: 100px;
            padding-left: 10px;
            justify-content: space-between;
            align-items: center;

            > button,
            sync-icon {
              min-width: 50px;
              min-height: 50px;
              order: 0;
              padding: 0;
            }
          }

          &.title-actions-block {
            right: 15px;
            padding-right: 0;

            a {
              height: 100%;
              color: var(--pos-light-blue);
              display: flex;
              align-items: center;

              i {
                margin-left: 10px;
              }
            }
          }
        }

        .invoice-number-title {
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 0 150px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        ion-buttons {
          justify-content: space-between;

          &[end] {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            order: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-current-user {
              padding: 0;
              display: flex;
              align-items: center;
              height: 100%;
              min-height: inherit;
              pos-image-loader-cap,
              person-placeholder {
                margin: 0 18px 0 8px;
              }
              pos-image-loader-cap {
                background-size: cover !important;
              }
            }
          }
        }
      }

      .right-navbar {
        position: relative;
        width: $cart-width;
      }
    }
  }

  .toolbar-background,
  .toolbar-background-ios {
    background: #fff;
    border-bottom: none !important;
  }

  ion-title {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 90px 1px;
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    pointer-events: none;
    font-size: 1.7rem;
    .toolbar-title {
      color: #000;
    }
  }

  button {
    color: var(--pos-light-blue);

    .text {
      text-transform: capitalize;
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px 0 0;
      font-weight: normal;
      font-size: 14px;

      & + .custom-icon {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  ion-buttons[slot='start'] {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    min-width: 100px;
    justify-content: space-between;
    align-items: center;

    ion-button,
    button,
    ion-menu-toggle > button,
    sync-icon {
      min-width: 45px !important;
      min-height: 45px !important;
      padding-left: 0 !important;
      order: 0;
      padding: 0;
      border-radius: 5px;
      @include media-md {
        min-width: 50px !important;
        min-height: 50px !important;
      }
    }

    > sync-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ion-buttons[slot='end'] {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
    text-align: right;
    button {
      min-width: 45px !important;
      min-height: 45px !important;
      @include media-md {
        min-width: 50px !important;
        min-height: 50px !important;
      }
    }
  }
}

ion-loading {
  ion-backdrop {
    background: none;
  }

  .loading-wrapper {
    color: #fff !important;
    background: rgba(0, 0, 0, 0.75) !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    padding: 28px 18px !important;

    .loading-spinner {
      padding: 0 16px;
    }
    .loading-content {
      margin-left: 0 !important;
    }
    .spinner-ios line,
    .spinner-ios-small line {
      stroke: #fff !important;
    }

    .spinner-crescent circle {
      stroke: #fff;
    }

    ion-spinner {
      width: 40px;
      height: 40px;
      &.spinner-crescent {
        --spinner-color: #fff;
        color: #fff !important;
      }
    }
  }
}

ion-toast {
  .toast-wrapper {
    left: 30px;
    right: 30px;
    width: auto;
    margin-top: 20px;
    border-radius: 3px !important;
  }

  &.success {
    .toast-wrapper {
      background-color: rgba(0, 167, 32, 0.75);
    }
  }

  &.info {
    .toast-wrapper {
      background-color: rgba(11, 106, 255, 0.75);
    }
  }

  &.error {
    .toast-wrapper {
      background-color: rgba(255, 24, 24, 0.75);
    }
  }

  &.warning {
    .toast-wrapper {
      background-color: rgba(255, 144, 0, 0.75);
    }
  }
}

.focus-outline :focus {
  outline: none !important;
}

.row-label-value {
  padding: 0;
  margin: 15px 0 0;
  font-size: 18px;
  color: #fff;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .row-label {
    margin-top: 3px;
    float: left;
    color: #333;
  }

  .row-value {
    float: right;
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 24px;
    font-weight: 600;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.split-line {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 122, 255, 0.25);
}

.quantity-block {
  overflow: hidden;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      display: block;
      float: left;
      width: 33.3%;
      list-style-type: none;
      border: 1px solid var(--pos-light-blue);

      a,
      span,
      input {
        display: block;
        height: 50px;
        line-height: 48px;
        font-size: 24px;
        color: var(--pos-light-blue);
        text-align: center;
        font-weight: 600;
        transition: all ease-in 0.1s;

        .custom-icon {
          display: inline-block;
          vertical-align: middle;
        }

        &.activated {
          background: var(--pos-light-blue);
          color: #fff;

          .custom-icon {
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
          }
        }
      }

      input {
        width: 100%;
        background: transparent;
        border: 0;
        &[type='number'] {
          -moz-appearance: textfield;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }

      &.quantity-subtract {
        border-radius: 3px 0 0 3px;
      }

      &.quantity-add {
        border-radius: 0 3px 3px 0;
      }

      &.quantity-value {
        border-left: none;
        border-right: none;

        span {
          color: #fff;
        }
      }
    }
  }
}

.no-events {
  * {
    pointer-events: none !important;
  }

  pointer-events: none !important;
  opacity: 0.4 !important;
}

.modal-wrapper {
  top: auto !important;
  left: auto !important;
  width: 100% !important;
  height: 100% !important;

  .toolbar-background {
    background: #fff;
    background: var(--pos-primary);
  }
  common-header {
    header {
      background-color: var(--pos-white);
    }
  }
  pos-header {
    .toolbar-background {
      background-color: var(--pos-white);
    }
  }
  ion-title {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 90px 1px;
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    pointer-events: none;
    .toolbar-title {
      color: #000;
    }
  }

  button {
    color: var(--pos-light-blue);

    .text {
      text-transform: capitalize;
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 0 0;
      font-weight: normal;
    }
  }

  ion-buttons[start] {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  ion-buttons[end] {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
    text-align: right;
  }
}

ion-toast {
  color: var(--pos-white);
  font-weight: 800;
  text-align: center;
  --max-width: 100vw;
  &.success-barcode-toast {
    --background: #00a720;
  }

  &.failure-barcode-toast {
    --background: #ff1818;
  }
}

.invoice-entries-list {
  ion-list {
    margin-top: -1px;

    .item {
      min-height: 70px;
      height: 70px;
      max-height: 70px;
      background: none;
      font-size: $collection-view-font-size-small;

      .item-inner {
        padding-right: 0;
        border-bottom: none;
        border-top: 1px solid var(--pos-dark-grey-border);
      }

      &.row-summary {
        &.row-total {
          border-top: 1px solid var(--pos-dark-grey-border);
          --inner-border-width: 0;
          .item-inner {
            border-top: none;
          }
        }

        &.row-overtotal {
          .col-title,
          .col-total {
            font-weight: 600;
          }
        }
      }

      div.col {
        display: flex;
        flex: 1;
        padding: 0 2%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.col-image {
          width: 50px;
          max-width: 50px;
          min-width: 50px;
          min-height: 50px;
          padding: 0;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .img-holder {
            position: relative;
            margin-left: 2px;
            display: inline-block;
            vertical-align: middle;
            width: 44px;
            height: 44px;
            padding: 1px;
            border: 1px solid transparent;
            background: url('/assets/images/icons/svg/placeholder.svg') no-repeat;
            background-size: contain;
            background-position: center center;

            &:before {
              content: '';
              border: 1px solid #e8e8e8;
              display: block;
              position: absolute;
              left: -3px;
              right: -3px;
              top: -3px;
              bottom: -3px;
            }

            img {
              display: none;
              max-width: 100%;
              max-height: 100%;
            }

            &.image-loaded.img-existed {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              background: none !important;

              img {
                display: inline-block;
                vertical-align: middle;
              }

              img + .item-placeholder {
                display: none;
              }
            }
          }

          .item-placeholder {
            display: flex;
            width: 100%;
            flex-direction: column;
            height: 100%;
            color: #fff;
            font-size: 14px;
            text-align: center;
            justify-content: space-around;
            white-space: normal;
            font-size: 10px;
          }
        }

        &.col-title {
          flex: 3;
          flex-direction: column;
        }

        &.col-amount,
        &.col-total,
        &.col-price {
          text-align: right;
          justify-content: flex-end;
        }

        &.col-price {
          span:first-child {
            display: none;
          }
        }

        &.col-amount {
          width: 80px;
          max-width: 80px;
          min-width: 80px;
        }
        &.col-image-title {
          flex: 4;
          padding-left: 0;
        }
      }

      ion-label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        height: 100%;
      }

      &.discounted-item {
        div.col.col-price {
          display: block;

          span {
            display: block;

            &:first-child {
              text-decoration: line-through;
            }

            &:nth-child(2) {
              color: var(--pos-green);
            }
          }
        }
      }

      &.discount-item {
        div.col.col-image .img-holder {
          background: none;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            margin: 0;
            display: inline-block;
            vertical-align: middle;
          }

          &:before {
            border: 1px solid var(--pos-green);
          }
        }

        div.col.col-price {
          span {
            display: inline;
          }
        }
      }
      &.invoice-entry-guest-item {
        min-height: 62px;
        height: 62px;
        max-height: 62px;
      }
    }
  }
}

ion-backdrop {
  background-color: rgba(10, 19, 38, 0.98) !important;
}

.pos-ripple-effect {
  position: relative;
  overflow: hidden;
}

.ripple-effect {
  border-radius: 50%;
  background-color: var(--pos-light-blue);
  position: absolute;
  transform: scale(0);
  opacity: 0.2;
}

.ripple-effect-active {
  animation: ripple-effect 0.4s linear;
}

.pos-wave-effect {
  position: relative;
}

.wave-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 300px;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0.2;
  background: var(--pos-light-blue);
}

.wave-effect-active {
  animation: wave-effect 0.4s forwards;
}

.my-pos-modal-animation {
  animation: myPosModalAnimate 0.28s forwards;
}

.cart-modal-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  position: absolute;
  background: var(--pos-white);
  @include padding-top-safe-area();
  @include media-custom(751) {
    top: initial;
    left: initial;
    right: 0;
    bottom: initial;
    height: 100%;
    width: $cart-width;
  }
  ion-header {
    ion-title {
      color: var(--pos-black);
    }
    &.header-ios {
      ion-toolbar {
        padding-top: 0;
      }
    }
  }
  .cart-modal-row {
    margin: 20px 0;
  }
  .cart-modal-body {
    padding: 0 40px;
    flex: 1;
    .cart-modal-input {
      color: var(--pos-light-blue);
      width: 100%;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
      text-align: right;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-weight: 600;
      &::-webkit-input-placeholder {
        color: rgba(#000, 0.2);
        font-weight: 600;
      }
      &::-moz-placeholder {
        color: rgba(#000, 0.2);
        font-weight: 600;
      }
      &:-moz-placeholder {
        color: rgba(#000, 0.2);
        font-weight: 600;
      }
      &:-ms-input-placeholder {
        color: rgba(#000, 0.2);
        font-weight: 600;
      }
    }
  }
  .cart-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 0;
    @include padding-bottom-safe-area(10px);
    @include media-custom(750) {
      @include padding-bottom-safe-area(20px);
    }
    .cart-modal-button-remove {
      flex-basis: 90px;
    }
    .cart-modal-button-done {
      flex-basis: 165px;
    }
  }
}

@media (max-width: 600px) {
  .invoice-entries-list {
    ion-list {
      .item {
        min-height: 52px;
        height: 52px;
        max-height: 52px;
        &.invoice-entry-guest-item {
          min-height: 52px;
          height: 52px;
          max-height: 52px;
        }
        &:not(.row-summary) {
          div.col {
            &.col-title {
              padding-right: 130px;
            }

            &.col-amount {
              display: none;
            }

            &.col-total {
              display: block;
              position: absolute;
              bottom: 7px;
              right: 16px;
              padding: 0;
            }

            &.col-price {
              position: absolute;
              display: block;
              top: 7px;
              right: 16px;
              padding: 0;
              font-size: 14px;
              color: var(--pos-grey-color);

              &:before {
                content: attr(amount);
              }
            }

            &.col-image {
              .img-holder {
                width: 40px;
                height: 40px;
              }
            }
          }
        }

        &.discounted-item {
          div.col.col-price {
            span {
              display: inline;

              &:first-child {
                display: none;
              }
            }
          }
        }

        &.row-summary {
          .col-price {
            display: none !important;
          }
        }
      }
    }
  }

  .platform-ios {
    .invoice-entries-list {
      ion-list {
        .item {
          &.row-summary {
            .col-total {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

// fix for https://github.com/driftyco/ionic/issues/8151
ion-modal {
  ion-backdrop {
    background: transparent;
  }

  ion-page > ion-header > .toolbar.statusbar-padding {
    padding-top: 20px;
    height: calc(44px + 20px);
    min-height: calc(44px + 20px);

    ion-title {
      padding-top: 20px !important;
    }
  }
  .cart-modal-inner {
    padding-top: 0;
    @include media-custom(750) {
      @include padding-top-safe-area();
    }
  }
}

.tablet-hide {
  display: none !important;
}

ion-header {
  &.header-ios {
    ion-navbar {
      ion-buttons {
        button {
          min-height: 48px;
          min-width: 48px;
        }
      }
    }
  }

  ion-navbar {
    ion-buttons {
      &[start] {
        margin-left: -4px;

        button {
          padding-left: 4px;
        }
      }

      &[end] {
        margin-right: -4px;

        button {
          padding-right: 4px;
        }
      }

      margin-top: -4px;
      margin-bottom: -4px;

      button {
        text-align: center;
        min-height: 54px;
        min-width: 44px !important;
      }
    }
  }

  .btn-current-user {
    min-height: initial;
    height: 100%;
  }
}

.toggle-md.toggle-checked .toggle-icon,
.toggle-ios.toggle-checked .toggle-icon {
  background-color: var(--pos-green);
}

checkout-invoice-details .item-ios {
  padding-right: 16px !important;
}

ion-header {
  ion-icon {
    font-size: 30px;
  }
  ion-toolbar {
    display: flex;
    --padding-start: 10px;
    --background: var(--pos-white);
  }
  ion-button {
    color: #007aff;
  }
}

ion-button,
button,
i,
select,
textarea,
input {
  outline: 0;
  &:focus {
    outline: 0;
  }
}

button {
  padding: 1px 6px;
}

ion-toggle {
  --track-background-checked: var(--pos-green);
  --background-checked: var(--pos-green);
  --handle-background-checked: var(--pos-white);
  .toggle-inner {
    top: -3px;
  }
}

.list-table ion-row {
  align-items: center;
  border-bottom: 0.55px solid #c8c7cc;

  &:first-child {
    border-top: 0.55px solid #c8c7cc;
  }
}

.ion-ios-arrow-round-down-outline:before {
  content: '\f118';
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

.hide {
  display: none !important;
}
[hidden] {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
}

.no-scroll {
  --overflow: hidden;
}

ion-modal {
  &.modal-default {
    --background: transparent;
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
  }
  .modal-wrapper {
    background: transparent !important;
  }
  ion-backdrop {
    display: none;
    @include media-sm {
      display: flex;
    }
  }
}

ion-list-header {
  &.ios {
    padding-left: 16px;
    position: relative;
    border-bottom: 0.55px solid #c8c7cc;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #333;
    background: 0 0;
    height: 60px;
    align-items: center;
  }
  &.md {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 13px;
    min-height: 4.5rem;
    border-top: 1px solid #dedede;
    font-size: 1.4rem;
    color: #757575;
    min-height: 60px;
  }
}

ion-content {
  &.ios {
    hr {
      height: 0.55px;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  &.md {
    hr {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}
.sc-ion-modal-ios-h {
  --backdrop-opacity: 0.2;
  --border-radius: 0;
}

a[disabled],
button[disabled],
html input[disabled] {
  opacity: 0.4;
  pointer-events: none;
}

.unprinted-kitchen-items-indicator {
  position: absolute;
  top: 6px;
  right: 6px;
}

.pos-checkbox {
  flex: 0 0 22px;
  padding-left: 0;
  margin-right: 10px;

  .checkbox-icon {
    position: relative;
    width: 21px;
    height: 21px;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    border-color: #c8c7cc;
    background-color: #fff;

    &.checkbox-checked {
      border-color: #327eff;
      background-color: #327eff;
    }

    .checkbox-inner {
      position: absolute;
      top: 4px;
      left: 7px;
      width: 4px;
      height: 9px;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      &.unselected {
        visibility: visible;
      }
    }
  }
}

.guests-modal {
  .modal-wrapper {
    @include media-custom(751) {
      width: 400px !important;
      height: 400px !important;
    }
  }

  .select-guest-list {
    padding: 0;
    ion-item {
      --min-height: 60px;
      --border-color: var(--pos-dark-grey-border);
      color: var(--pos-light-blue);
      guest-button {
        width: 100%;
        margin-left: 6px;
        &.add-new-guest-button {
          margin-left: 0;
        }
      }
    }
  }
}

.autofill-alert {
  .alert-input-wrapper {
    position: relative;
  }
  .autofill-alert-list {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -31px;
    background: var(--pos-white);
    color: var(--pos-black);
    border-radius: 5px;
    box-shadow: 0 0 2px grey;
    z-index: 1;
    .autofill-alert-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: 500;
      padding-left: 10px;
      height: 30px;
      &:hover {
        background: rgba($color: var(--pos-black), $alpha: 0.04);
      }
    }
  }
  &.md {
    .autofill-alert-list {
      bottom: -35px;
      border-radius: 2px;
      box-shadow: 0 0 1px var(--pos-grey-color);
      .autofill-alert-item {
        height: 40px;
      }
    }
  }
}

.item-in-list {
  ion-item {
    --padding-start: 0;
    --inner-padding-start: 16px;
    --inner-padding-end: 16px;
    min-height: 60px;
    height: 60px;
    max-height: 60px;
    --min-height: 60px;
    --border-color: var(--pos-grey-border);
  }
  .item-in-list-cancelled {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 20px);
      height: 1px;
      top: 50%;
      border-bottom: 1px solid var(--pos-red);
      z-index: 2;
    }
  }
  .item-in-list-cancel-button {
    width: 88px;
    color: var(--pos-white);
    font-size: 16px;
    background: var(--pos-red);
    margin: 0;
  }
  ion-item-options {
    border-bottom-width: 1px;
  }
}

.default-cart-image {
  width: 44px;
  height: 44px;
  padding: 1px;
  border: 1px solid transparent;
  background: url('/assets/images/icons/svg/placeholder.svg') no-repeat;
  background-size: contain;
  background-position: center center;
}

.pos-picker {
  overflow: hidden;
  display: block;
  .pos-picker-select {
    position: absolute;
    top: 40%;
    left: 0px;
    width: 94%;
    pointer-events: none;
    height: 20%;
    border-radius: 5px;
    background-color: var(--pos-grey-bg);
    margin: 0 5px;
  }
  .pos-picker-columns {
    display: flex;
    position: relative;
    justify-content: center;
    margin-bottom: var(--ion-safe-area-bottom, 0);
    contain: strict;
    direction: ltr;
    overflow: hidden;
    height: 100%;
    perspective: 1800px;
  }
  .pos-picker-right {
    justify-content: flex-end;
  }
  .pos-picker-left {
    justify-content: flex-start;
  }
  .pos-picker-before,
  .pos-picker-after {
    position: absolute;
    width: 100%;
    transform: translateZ(0px);
    z-index: 1;
    pointer-events: none;
    left: 0px;
    height: 40%;
    border-radius: 5px;
  }
  .pos-picker-before {
    top: 0px;
    background: linear-gradient(
      to bottom,
      var(--background, var(--ion-background-color, #fff)) 20%,
      rgba(var(--background-rgb, var(--ion-background-color-rgb, 255, 255, 255)), 0.8) 100%
    );
  }
  .pos-picker-after {
    top: 60%;
    background: linear-gradient(
      to top,
      var(--background, var(--ion-background-color, #fff)) 20%,
      rgba(var(--background-rgb, var(--ion-background-color-rgb, 255, 255, 255)), 0.8) 100%
    );
  }
}

.pos-content-picker {
  .content-box {
    box-shadow: 2px 2px 20px 1px rgb(0 0 0 / 20%);
    .close-button {
      display: none;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--pos-light-blue, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  swiper-slide {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    text-align: center;
    box-sizing: border-box;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
}

ion-select {
  --highlight-color: transparent;
  --ion-color-step-500: transparent;
  --ion-color-step-650: transparent;
}

@media screen and (orientation: landscape) {
  ion-list-header {
    &.ios {
      padding-left: calc(constant(safe-area-inset-left) + 16px);
      padding-left: calc(env(safe-area-inset-left) + 16px);
    }
  }
}
.mobile-new-hide {
  display: none;
  @include media-custom(750) {
    display: inherit;
  }
}
.tablet-new-hide {
  display: inherit;
  @include media-custom(750) {
    display: none;
  }
}

.intercom-namespace {
  div.intercom-messenger-frame {
    transform-origin: left bottom;
  }
}

@media (max-width: 750px) {
  ion-header {
    .btn-current-user {
      display: none !important;
    }
  }

  .mobile-hide {
    display: none !important;
  }

  .tablet-hide {
    display: block !important;
  }

  .tablet-flex-hide {
    display: flex !important;
  }

  ion-header button .text.tablet-hide {
    display: inline-block !important;
  }

  checkout-invoice-details .invoice-entries-list .item-inner {
    padding-right: 0 !important;
  }

  product-details-modal .modal-header {
    margin-top: 10px;
  }

  ion-title.title.title-ios {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plt-ios {
    ion-modal {
      @include margin-top-safe-area();
    }
  }
}

@media (max-height: 750px) {
  .quantity-block ul li a,
  .quantity-block ul li span,
  .quantity-block ul li input {
    height: 44px;
    line-height: 42px;
  }
}

@media (max-height: 650px) {
  .row-label-value {
    font-size: 14px !important;

    .row-value {
      font-size: 18px !important;
    }
  }

  .quantity-block ul li a,
  .quantity-block ul li span,
  .quantity-block ul li input {
    font-size: 20px;
  }
}

@media (max-height: 550px) {
  .quantity-block ul li a,
  .quantity-block ul li span,
  .quantity-block ul li input {
    height: 30px;
    line-height: 28px;
  }
}

@media screen and (orientation: landscape) {
  app-paymash.ios .scroll-content {
    padding-left: 0;
  }
}

// keyframes
@include keyframes('myPosModalAnimate') {
  100% {
    opacity: 1;
  }
}

@include keyframes('wave-effect') {
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

@include keyframes('ripple-effect') {
  100% {
    opacity: 0;
    transform: scale(3);
  }
}
