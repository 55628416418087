//safe-area
$safe-area-const-bottom: constant(safe-area-inset-bottom);
$safe-area-const-top: constant(safe-area-inset-top);
$safe-area-env-bottom: env(safe-area-inset-bottom);
$safe-area-env-top: env(safe-area-inset-top);

@mixin top-safe-area($value: 0px) {
  top: $value;
  @supports (top: $safe-area-const-top) {
    @include setSafeArea(top, $value, $safe-area-const-top);
  }
  @supports (top: $safe-area-env-top) {
    @include setSafeArea(top, $value, $safe-area-env-top);
  }
}

@mixin padding-top-safe-area($value: 0px) {
  padding-top: $value;
  @supports (padding-top: $safe-area-const-top) {
    @include setSafeArea(padding-top, $value, $safe-area-const-top);
  }
  @supports (padding-top: $safe-area-env-top) {
    @include setSafeArea(padding-top, $value, $safe-area-env-top);
  }
}

@mixin margin-top-safe-area($value: 0px) {
  margin-top: $value;
  @supports (margin-top: $safe-area-const-top) {
    @include setSafeArea(margin-top, $value, $safe-area-const-top);
  }
  @supports (margin-top: $safe-area-env-top) {
    @include setSafeArea(margin-top, $value, $safe-area-env-top);
  }
}

@mixin margin-bottom-safe-area($value: 0px) {
  margin-bottom: $value;
  @supports (margin-bottom: $safe-area-const-bottom) {
    @include setSafeArea(margin-bottom, $value, $safe-area-const-bottom);
  }
  @supports (margin-bottom: $safe-area-env-bottom) {
    @include setSafeArea(margin-bottom, $value, $safe-area-env-bottom);
  }
}

@mixin padding-bottom-safe-area($value: 0px) {
  padding-bottom: $value;
  @supports (padding-bottom: $safe-area-const-bottom) {
    @include setSafeArea(padding-bottom, $value, $safe-area-const-bottom);
  }
  @supports (padding-bottom: $safe-area-env-bottom) {
    @include setSafeArea(padding-bottom, $value, $safe-area-env-bottom);
  }
}

@mixin padding-bottom-safe-area-calc($value: 0px) {
  padding-bottom: $value;
  @supports (padding-bottom: $safe-area-const-bottom) {
    padding-bottom: unquote('calc(#{$safe-area-const-bottom} + #{$value})');
  }
  @supports (padding-bottom: $safe-area-env-bottom) {
    padding-bottom: unquote('calc(#{$safe-area-env-bottom} + #{$value})');
  }
}

@mixin bottom-safe-area($value: 0px) {
  bottom: $value;
  @supports (bottom: $safe-area-const-bottom) {
    @include setSafeArea(bottom, $value, $safe-area-const-bottom);
  }
  @supports (bottom: $safe-area-env-bottom) {
    @include setSafeArea(bottom, $value, $safe-area-env-bottom);
  }
}

@mixin setSafeArea($property, $value, $safeArea) {
  @if $value == 0 or $value == 0px {
    #{$property}: unquote('#{$safeArea}');
  } @else {
    #{$property}: unquote('max(#{$value}, #{$safeArea})');
  }
}

@mixin height-safe-area-calc-sub($value) {
  height: unquote('calc(100% - #{$value})');
  @supports (padding-bottom: $safe-area-const-bottom) {
    height: unquote('calc(100% - #{$value} - #{$safe-area-const-bottom})');
  }
  @supports (padding-bottom: $safe-area-env-bottom) {
    height: unquote('calc(100% - #{$value} - #{$safe-area-env-bottom})');
  }
}

@mixin max-height-safe-area-calc-sub-important($value) {
  max-height: unquote('calc(100% - #{$value}) !important');
  @supports (padding-bottom: $safe-area-const-bottom) {
    max-height: unquote('calc(100% - #{$value} - #{$safe-area-const-bottom}) !important');
  }
  @supports (padding-bottom: $safe-area-env-bottom) {
    max-height: unquote('calc(100% - #{$value} - #{$safe-area-env-bottom}) !important');
  }
}
